import React from "react";

import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
  useBreakpointValue,
  extendTheme,
  Box,
  Flex,
  Heading,
  Image,
  Card,
  Center,
  Spacer,
  Text,
  SimpleGrid,
  Link,
  Select,
  Button,
} from "@chakra-ui/react";
import "./App.css";
import Logo from "./logo.jpeg";
import ImageOne from "./imageOne.jpeg";
import ImageTwo from "./imageTwo.jpeg";
import ImageThree from "./imageThree.jpeg";
import ImageFour from "./imageFour.jpeg";
import ImageFive from "./imageFive.jpeg";
import { FaSquareXTwitter, FaDiscord } from "react-icons/fa6";
import { IoMdArrowRoundForward } from "react-icons/io";

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

// 3. Extend the theme
const theme = extendBaseTheme({ breakpoints });

function App() {
  return (
    <ChakraBaseProvider theme={theme}>
      <Card
        backgroundColor={"gray.800"}
        bgSize="cover"
        minHeight={"100vh"}
        paddingLeft={{ md: 24 }}
        paddingRight={{ md: 24 }}
      >
        {/* Header Section */}
        <Flex
          as="header"
          align="center"
          justify="space-between"
          padding={4}
          minWidth="100%"
          className="nav_bar"
        >
          {/* Logo */}
          <Box>
            <Image
              src={Logo}
              borderRadius={"100%"}
              alt="predictcoin"
              width={{ sm: "90px", md: "90px" }}
            />
          </Box>

          <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={{ sm: 10, md: 10 }}>
            <Center opacity={{ sm: 0, md: 1 }} padding={2}>
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                Home
              </Text>
            </Center>

            <Center opacity={{ sm: 0, md: 1 }} padding={2}>
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                Trending
              </Text>
            </Center>

            <Center
              opacity={{ sm: 0, md: 1 }}
              borderRadius={8}
              backgroundColor={"#2AAA8A"}
              padding={2}
            >
              <Text fontSize={{ sm: "smaller" }} color={"white"}>
                Buy Flow
              </Text>
            </Center>
          </SimpleGrid>
        </Flex>

        <Text
          marginTop={6}
          marginBottom={6}
          fontSize="x-large"
          as={"b"}
          color={"white"}
        >
          Trending
        </Text>
        <Center
          flexDirection="row"
          borderRadius={10}
          overflow={"hidden"}
          height={{ sm: "500px", md: "350px" }}
          backgroundColor={"#2AAA8A"}
          paddingLeft={4}
          marginBottom={20}
        >
          <Box width={{ sm: "50%", md: "30%" }}>
            <Text fontSize="large" as={"b"}>
              Presale Now
            </Text>

            <Text fontSize={{ sm: "small", md: "medium" }} color={"white"}>
              Project
            </Text>

            <Text fontSize="x-large" as={"b"}>
              $Fldt
            </Text>
            <Text fontSize={{ sm: "small", md: "medium" }}>
              When selling your assets is not an option. Lend, Borrow, Rent,
              Boost. Decentralized. Permission-less. Multi-chain.
            </Text>
            <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
              Price: 0.549Ada
            </Text>
            <br />
            <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
              Market cap: 56.44M Ada
            </Text>
            <br />
            <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
              Daily Volume: 8.41M Ada
            </Text>

            <Link
              href="https://www.taptools.io/charts/token?pairID=cab2059b754430ae6e09a547f94d61de11901573f1d2388de95cbb0c.48554e545f4144415f4e4654"
              isExternal
            >
              <Button
                borderRadius={10}
                width={"90%"}
                alignSelf={"center"}
                padding={2}
                marginTop={7}
                backgroundColor={"black"}
              >
                <Text as={"b"} color={"white"} fontSize={"large"}>
                  Buy Now
                </Text>
              </Button>
            </Link>
          </Box>

          <Image
            src={Logo}
            alt="predictcoin"
            height={{ sm: "500px", md: "350px" }}
            width={{ sm: "50%", md: "70%" }}
          />
        </Center>

        <Text
          marginTop={6}
          marginBottom={6}
          fontSize="x-large"
          as={"b"}
          color={"white"}
        >
          Launches
        </Text>
        <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={{ sm: 4, md: 10 }}>
          <Center
            flexDirection="row"
            borderRadius={10}
            overflow={"hidden"}
            height={{ sm: "750px", md: "600px" }}
            backgroundColor={"#2AAA8A"}
            paddingRight={4}
          >
            <Image
              src={ImageOne}
              alt="predictcoin"
              height={{ sm: "750px", md: "600px" }}
              width={{ sm: "40%", md: "50%" }}
            />

            <Box paddingLeft={4} width={{ sm: "60%", md: "50%" }}>
              <Text fontSize="large" as={"b"}>
                Presale Now
              </Text>

              <Text fontSize={{ sm: "small", md: "medium" }} color={"white"}>
                Project
              </Text>

              <Text fontSize="x-large" as={"b"}>
                Hunt
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }}>
                DexHunter - Biggest Cardano DEX Aggregator for Optimal DeFi
                Trading?
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Price: 0.595Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Market cap: 59.46M Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Daily volume: 935k Ada
              </Text>

              <Link
                href="https://www.taptools.io/charts/token?pairID=cab2059b754430ae6e09a547f94d61de11901573f1d2388de95cbb0c.48554e545f4144415f4e4654"
                isExternal
              >
                <Button
                  borderRadius={10}
                  width={"90%"}
                  alignSelf={"center"}
                  padding={2}
                  marginTop={7}
                  backgroundColor={"black"}
                >
                  <Text
                    as={"b"}
                    color={"white"}
                    fontSize={{ sm: "x-small", md: "large" }}
                  >
                    Buy Now
                  </Text>
                </Button>
              </Link>
            </Box>
          </Center>

          <Center
            flexDirection="row"
            borderRadius={10}
            overflow={"hidden"}
            height={{ sm: "750px", md: "600px" }}
            backgroundColor={"#2AAA8A"}
            paddingRight={4}
          >
            <Image
              src={ImageTwo}
              alt="predictcoin"
              height={{ sm: "750px", md: "600px" }}
              width={{ sm: "40%", md: "50%" }}
            />

            <Box paddingLeft={4} width={{ sm: "60%", md: "50%" }}>
              <Text fontSize="large" as={"b"}>
                Presale Now
              </Text>

              <Text fontSize={{ sm: "small", md: "medium" }} color={"white"}>
                Project
              </Text>

              <Text fontSize="x-large" as={"b"}>
                Fren
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }}>
                THE ONLY PFP YOU NEED TBH
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Price: 0.0000249Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Market cap: 9.7M Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Daily volume: 627k Ada
              </Text>

              <Link
                href="https://www.taptools.io/charts/token?pairID=0be55d262b29f564998ff81efe21bdc0022621c12f15af08d0f2ddb1.2fbc26cfc11e2d1ac6ea99da484e97c4eb13e7587c1175afe4251d26f1adf4bb"
                isExternal
              >
                <Button
                  borderRadius={10}
                  width={"90%"}
                  alignSelf={"center"}
                  padding={2}
                  marginTop={7}
                  backgroundColor={"black"}
                >
                  <Text
                    as={"b"}
                    color={"white"}
                    fontSize={{ sm: "x-small", md: "large" }}
                  >
                    Buy Now
                  </Text>
                </Button>
              </Link>
            </Box>
          </Center>

          <Center
            flexDirection="row"
            borderRadius={10}
            overflow={"hidden"}
            height={{ sm: "750px", md: "600px" }}
            backgroundColor={"#2AAA8A"}
            paddingRight={4}
          >
            <Image
              src={ImageThree}
              alt="predictcoin"
              height={{ sm: "750px", md: "600px" }}
              width={{ sm: "40%", md: "50%" }}
            />
            <Box paddingLeft={4} width={{ sm: "60%", md: "50%" }}>
              <Text fontSize="large" as={"b"}>
                Presale Now
              </Text>

              <Text fontSize={{ sm: "small", md: "medium" }} color={"white"}>
                Project
              </Text>

              <Text fontSize="x-large" as={"b"}>
                Snek
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }}>
                a meme, and a memecoin
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Price: 0.00187 Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Market Cap: 142.22M Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Daily Volume: 1.7M Ada
              </Text>

              <Button
                borderRadius={10}
                width={"90%"}
                alignSelf={"center"}
                padding={2}
                marginTop={7}
                backgroundColor={"black"}
              >
                <Text
                  as={"b"}
                  color={"white"}
                  fontSize={{ sm: "x-small", md: "large" }}
                >
                  Buy Now
                </Text>
              </Button>
            </Box>
          </Center>

          <Center
            flexDirection="row"
            borderRadius={10}
            overflow={"hidden"}
            height={{ sm: "750px", md: "600px" }}
            backgroundColor={"#2AAA8A"}
            paddingRight={4}
          >
            <Image
              src={ImageFour}
              alt="predictcoin"
              height={{ sm: "750px", md: "600px" }}
              width={{ sm: "40%", md: "50%" }}
            />
            <Box paddingLeft={4} width={{ sm: "60%", md: "50%" }}>
              <Text fontSize="large" as={"b"}>
                Presale Now
              </Text>

              <Text fontSize={{ sm: "small", md: "medium" }} color={"white"}>
                Project
              </Text>

              <Text fontSize="x-large" as={"b"}>
                $Fldt
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }}>
                When selling your assets is not an option. Lend, Borrow, Rent,
                Boost. Decentralized. Permission-less. Multi-chain.
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Price: 0.549Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Market cap: 56.44M Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Daily Volume: 8.41M Ada
              </Text>

              <Link
                href="https://www.taptools.io/charts/token?pairID=0be55d262b29f564998ff81efe21bdc0022621c12f15af08d0f2ddb1.63f2cbfa5bf8b68828839a2575c8c70f14a32f50ebbfa7c654043269793be896"
                isExternal
              >
                <Button
                  borderRadius={10}
                  width={"90%"}
                  alignSelf={"center"}
                  padding={2}
                  marginTop={7}
                  backgroundColor={"black"}
                >
                  <Text
                    as={"b"}
                    color={"white"}
                    fontSize={{ sm: "x-small", md: "large" }}
                  >
                    Buy Now
                  </Text>
                </Button>
              </Link>
            </Box>
          </Center>

          <Center
            flexDirection="row"
            borderRadius={10}
            overflow={"hidden"}
            height={{ sm: "750px", md: "600px" }}
            backgroundColor={"#2AAA8A"}
            paddingRight={4}
          >
            <Image
              src={ImageFive}
              alt="predictcoin"
              height={{ sm: "750px", md: "600px" }}
              width={{ sm: "40%", md: "50%" }}
            />
            <Box paddingLeft={4} width={{ sm: "60%", md: "50%" }}>
              <Text fontSize="large" as={"b"}>
                Presale Now
              </Text>

              <Text fontSize={{ sm: "small", md: "medium" }} color={"white"}>
                Project
              </Text>

              <Text fontSize="x-large" as={"b"}>
                Min
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }}>
                The first multi-pool decentralized exchange on Cardano
              </Text>
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Price: 0.063Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Market cap: 190.85M Ada
              </Text>
              <br />
              <Text fontSize={{ sm: "small", md: "medium" }} as={"b"}>
                Daily volume: 430k Ada
              </Text>

              <Link
                href="https://www.taptools.io/charts/token?pairID=0be55d262b29f564998ff81efe21bdc0022621c12f15af08d0f2ddb1.6aa2153e1ae896a95539c9d62f76cedcdabdcdf144e564b8955f609d660cf6a2"
                isExternal
              >
                <Button
                  borderRadius={10}
                  width={"90%"}
                  alignSelf={"center"}
                  padding={2}
                  marginTop={7}
                  backgroundColor={"black"}
                >
                  <Text
                    as={"b"}
                    color={"white"}
                    fontSize={{ sm: "x-small", md: "large" }}
                  >
                    Buy Now
                  </Text>
                </Button>
              </Link>
            </Box>
          </Center>
        </SimpleGrid>

        <Box as="footer" mt={8} textAlign="center">
          <Flex
            as="footer"
            align="center"
            justify="space-between"
            padding={4}
            minWidth="100%"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <Text
              onClick={() => {
                document.execCommand(
                  "copy",
                  true,
                  "1aedec14e2ca0868a0e00ca2d5344a291ebd0ba41b86438a8204502b"
                );
                alert("copied");
              }}
              fontSize={"small"}
              as={"b"}
              color={"white"}
            >
              Policy ID: 1aedec14....
            </Text>

            <Box mx={2}>
              <SimpleGrid columns={2} spacing={10}>
                <Link href="https://x.com/flowcoinada?s=21" isExternal>
                  <FaSquareXTwitter size={30} color="white" />
                </Link>
                <Link href="/" isExternal>
                  <FaDiscord size={30} color="white" />
                </Link>
              </SimpleGrid>
            </Box>
          </Flex>
        </Box>
      </Card>
    </ChakraBaseProvider>
  );
}

export default App;
